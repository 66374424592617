<template>
  <div class="flex justify-center my-2 mx-auto">
    <b-button
      :to="'/events-manager/applicant-profile/' + params.booking_id"
      icon-pack="feather"
      icon="icon-eye"
      variant="warning"
      class="shadow-lg btn-back-to-top"
    >View More Info</b-button>
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'CellRendererProfile',
  props: {
    params: Object,
  },
  components: {
    BButton,
  },
  created() {
  },
}
</script>
