<template>
  <div>
    <b-modal
      v-model="booking_popup"
      centered
      hide-footer
      fullscreen
      title="Manage your booking slots"
    >
      <booking-slots
        v-if="booking_popup"
        :is_popup="true"
      />
    </b-modal>
    <b-modal
      v-model="interview_popup"
      centered
      hide-footer
      title="Schedule for interview"
    >
      <div class="vx-row mx-auto my-1">
        <h6 class="mt-1 mb-1">
          Please select the interview date and time below
        </h6>

        <h6>From</h6>
        <flat-pickr
          v-model="interview_date"
          :config="configInterviewPicker"
          class="w-full mx-auto mb-1"
          placeholder="Select interview date and time"
        />

        <h6>To</h6>
        <flat-pickr
          v-model="interview_date"
          :config="configInterviewPicker"
          class="w-full mx-auto"
          placeholder="Select interview date and time"
        />
      </div>

      <div class="vx-row mx-auto">
        <div
          v-if="params && params.event_type !== 'speed_interview'"
          class="vx-col mx-auto"
        >
          <b-button
            :disabled="
              interview_date &&
                interview_date.length > 1 &&
                interview_start_time.length > 1
                ? false
                : true
            "
            class="shadow-lg"
            variant="warning"
            @click="scheduleInterview"
          >Schedule interview</b-button>
        </div>

        <div
          v-else
          class="vx-col w-100 mx-auto"
        >
          <b-button
            :disabled="interview_date.length > 1 ? false : true"
            class="w-100 shadow-lg"
            variant="warning"
            @click="scheduleInterview"
          >Schedule interview</b-button>
        </div>
      </div>
    </b-modal>

    <b-button
      v-if="params && params.interview_date_time && params.interview_date_time.date"
      ref="interview_date"
      v-b-tooltip.hover.top="
        'Click on this button to change the interview date.'
      "
      class="mt-4 mb-4 shadow-lg"
      variant="warning"
      @click="openInterview"
    >
      {{ humanFriendlyDate(params.interview_date_time.date) }} ({{
        params.interview_date_time.start_time
      }}
      - {{ params.interview_date_time.end_time }})
    </b-button>

    <b-button
      v-else
      ref="interview_date"
      class="mt-4 mb-4 shadow-lg"
      variant="warning"
      @click="openInterview"
    >Schedule interview</b-button>
  </div>
</template>

<script>
import { format, sub } from 'date-fns'

import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {
  BCard,
  BFormInput,
  BFormRadio,
  BButton,
  BRow,
  BModal,
  VBTooltip,
  BAlert,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
} from 'bootstrap-vue'
import BookingSlots from '../BookingSlots.vue'

export default {
  components: {
    flatPickr,
    BookingSlots,

    BCard,
    BFormRadio,
    BFormInput,
    BButton,
    BRow,
    BModal,
    BAlert,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
  },
  props: {
    params: Object,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      configdateTimePicker: {
        enableTime: true,
        enableSeconds: true,
        noCalendar: true,
      },
      configInterviewPicker: {
        altInput: true,
        altFormat: 'F j, Y',
        enableTime: false,
        enableSeconds: true,
        inline: true,
        minDate: sub(new Date(), { days: 1 }),
      },

      interview_date: '',
      interview_start_time: '',
      interview_end_time: '',
      all_available_slots: [],
      filtered_slots: [],
      interview_popup: false,
      booking_popup: false,
      booking_url: '',
      duration: '',
    }
  },

  watch: {
    interview_date(val) {
      // this.getSlots();

      // const interview_date = format(new Date(val), "yyyy-MM-dd");

      this.getSlots(val)
    },
  },
  created() {
    this.getSlots()
    this.getAvailableDates()
    if (this.interview_date.length === 0) {
      this.interview_date = format(new Date(), 'yyyy-MM-dd')
    }
  },

  methods: {
    showBookings() {
      this.booking_popup = true
    },
    getAvailableDates() {
      // this.$http
      //   .get('/api/available-dates')
      //   .then(response => {
      //     const { success } = response.data
      //     if (success) {
      //       const unavailable = response.data.unavailable_dates
      //       this.configInterviewPicker = {
      //         altInput: true,
      //         altFormat: 'F j, Y',
      //         enableTime: false,
      //         enableSeconds: true,
      //         inline: true,
      //         minDate: sub(new Date(), { days: 1 }),
      //         disable: [
      //           function (date) {
      //             // return true to disable
      //             return unavailable.includes(date.getDay())
      //           },
      //         ],
      //       }
      //     }
      //   })
      //   .catch(error => {})
    },
    humanFriendlyDate(date_string) {
      return format(new Date(date_string), 'E, do LLL, yyyy')
    },

    openInterview() {
      const start_date = this.params.event_start_date
      const end_date = this.params.event_end_date

      this.interview_date = this.params.interview_date_time.date
      this.interview_start_time = this.params.interview_date_time.start_time
      this.interview_end_time = this.params.interview_date_time.end_time
      this.interview_date_time = this.params.interview_date_time

      this.interview_popup = true

      this.getSlots()
      this.configInterviewPicker = {
        altInput: true,
        altFormat: 'F j, Y',
        enableTime: false,
        enableSeconds: true,
        minDate: new Date(),
        maxDate: end_date,
      }
    },

    scheduleInterview() {
      if (this.interview_date) {
        const selected_date = new Date(this.interview_date)

        if (selected_date.getFullYear() === 1970) {
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Interview schedule notification',
                icon: 'AlertCircleIcon',
                text: 'Please select a valid date and time',
                variant: 'warning',
              },
            },
            { timeout: this.$longestTimeout },
          )
        } else {
          // if (this.dateWithinRange(selected_date, interview_dates)) {
          //   this.$vs.notify({
          //     time: 10000,
          //     title: "Interview schedule notification",
          //     text: "Applicant is scheduled to have an interview at this time. Please select another interview time or date.",
          //     color: "warning",
          //     iconPack: "feather",
          //     position: "top-right",
          //     icon: "icon-check-circle",
          //   });
          // } else {

          const all_data = {
            booking_id: this.params.booking_id,
            interview_date_time: {
              date: this.interview_date,
              start_time: this.interview_start_time,
              end_time: '',
            },
            duration: this.duration,
            position: this.params.position,
            company: this.params.company,
          }

          this.updateBooking(all_data, 'interview')
        }
      } else {
        this.$toast(
          {
            component: this.$toastContent,
            props: {
              title: 'Interview schedule notification',
              icon: 'AlertCircleIcon',
              text: 'Please select a valid date',
              variant: 'warning',
            },
          },
          { timeout: this.$longestTimeout },
        )
      }
    },

    getSlots(updated_interview_date = null) {
      const all_blocked_times = []

      let { interview_date } = this

      if (interview_date) {
        if (interview_date.length === 0) {
          interview_date = format(new Date(), 'yyyy-MM-dd')
        }
      } else {
        interview_date = format(new Date(), 'yyyy-MM-dd')
      }

      if (updated_interview_date !== null) {
        interview_date = updated_interview_date
      }
      // console.log('booking_id', this.params.booking_id)

      // this.$http
      //   .get(
      //     `/api/available-slots/${this.params.booking_id}/${interview_date}`,
      //   )
      //   .then(response => {
      //     const output = response.data.slots
      //     this.duration = response.data.duration
      //     const { success } = response.data
      //     if (success) {
      //       this.filtered_slots = output.map(slot => ({
      //         text: slot,
      //         value: slot,
      //       }))
      //     }
      //   })
      //   .catch(error => {})
    },

    updateBooking(all_data, data_type) {
      this.$http
        .put('/api/book', { data: all_data })
        .then(response => {
          const { success } = response.data
          if (success) {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title:
                    data_type === 'status'
                      ? 'Application Status'
                      : 'Interview Scheduling',
                  icon: 'AlertCircleIcon',
                  text:
                    data_type === 'status'
                      ? 'Application status updated successfully.'
                      : 'Interview scheduled successfully and applicant has been notified.',
                  variant: 'success',
                },
              },
              { timeout: this.$longestTimeout },
            )
            this.application_status_popup = false
            window.location.reload()
          } else {
            this.$toast(
              {
                component: this.$toastContent,
                props: {
                  title:
                    data_type === 'status'
                      ? 'Application Status'
                      : 'Interview Scheduling',
                  icon: 'AlertCircleIcon',
                  text:
                    data_type === 'status'
                      ? 'Application status update failed.'
                      : "You haven't made any changes to the interview details.",
                  variant: 'danger',
                },
              },
              { timeout: this.$longestTimeout },
            )
          }
        })
        .catch(error => {})
    },

    dateChanged(val, newVal, instance) {},
  },
}
</script>
